import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Loading from './pages/componenets/loading.jsx';
// Lazy load components
const ModernLandingPageWithTitle = lazy(() => import('./pages/LandingPageWithTitle'));
const PrivacyWithTitle = lazy(() => import('./pages/PrivacyWithTitle'));
const TermsWithTitle = lazy(() => import('./pages/TermsWithTitle'));
const EndUserWithTitle = lazy(() => import('./pages/EndUserWithTitle'));
const ContactPageWithTitle = lazy(() => import('./pages/ContactPageWithTitle'));
const NotificationPageWithTitle = lazy(() => import('./pages/NotificationPageWithTitle'));
const PricingPageWithTitle = lazy(() => import('./pages/PricingPageWithTitle'));
const FAQWithTitle = lazy(() => import('./pages/FAQPage'));
const ITPWithTitle = lazy(() => import('./pages/ITPPageWithTitle'));

function App() {
  return (
    <Router>
      <Suspense fallback={<Loading/>}>
        <Routes>
          <Route path="/" element={<ModernLandingPageWithTitle />} />
          <Route path="/privacy" element={<PrivacyWithTitle />} />
          <Route path="/terms" element={<TermsWithTitle />} />
          <Route path="/programare" element={<EndUserWithTitle />} />
          <Route path="/contact" element={<ContactPageWithTitle />} />
          <Route path="/notification" element={<NotificationPageWithTitle />} />
          <Route path="/pricing" element={<PricingPageWithTitle />} />
          <Route path="/faq" element={<FAQWithTitle />} />
          <Route path="/itp" element={<ITPWithTitle />} />
          <Route path="/programare-itp" element={<EndUserWithTitle />} />
          <Route path="/programare-mecanic" element={<EndUserWithTitle />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;